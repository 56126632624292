const linksText = `
https://www.youtube.com/watch?v=vPe2_IGK_Ng
https://arroyo.jon-kyle.com/
https://ccp.arizona.edu/node/2478
https://butdoesitfloat.com/Index
http://www.buildingparis.fr/#paulin
https://www.youtube.com/watch?v=5bjIRHGNGHQ
https://progressbar.club/
http://dirt.haus/
http://brutalistwebsites.com/
http://violentopaque.space/
http://genderfailarchiveproject.com/
https://www.alexgamsujenkins.com/
https://www.youtube.com/watch?v=iAUITk6hvYY
http://www.cesurapublish.com/
https://www.biodiversitylibrary.org/item/84203#page/19/mode/1up
https://www.nts.live/shows/british-library-sound-archive/episodes/british-library-sound-archive-26th-october-2017
https://www.nts.live/editorial/british-library-sound-archive-anglo-colombian
https://vimeo.com/170931719
https://www.moma.org/interactives/objectphoto/publications/780.html
http://www.kesselskramerpublishing.com/catalogue/useful-photography-13/
https://vimeo.com/68772630
http://www.byobworldwide.com/
https://www.moma.org/collection/works/80890
https://vimeo.com/129551722
http://www.variant.org.uk/
https://vimeo.com/96240850
https://vimeo.com/161490718
https://pirateproxy.gdn/torrent/10089188/Nico_Icon_[1995]
https://rateyourmusic.com/list/Gene_Wilders_Hair/jim-orourkes-favorite-music-influences/
https://www.nytimes.com/2016/12/30/arts/design/raymond-pettibon-new-museum.html
https://www.newyorker.com/culture/culture-desk/the-word-of-mouth-resurgence-of-arthur-russell
https://www.transartists.org/about
https://www.youtube.com/watch?v=_GpfT2sa9eg&index=341&list=LLTO4TTUkoQgfqBNFdtdJBsg&t=0s
https://en.wikipedia.org/wiki/Harry_Everett_Smith
https://www.vice.com/en_us/article/vdxg83/how-do-you-cope-when-your-partner-has-depression-144
https://www.youtube.com/watch?v=HmKGusadv08&index=123&list=LLTO4TTUkoQgfqBNFdtdJBsg
https://vimeo.com/92690024
https://archive.org/details/DrummerMagazine/page/n19
https://archive.org/details/BettyPageInBondageVol1/page/n1
http://eroticcult.tumblr.com/
https://www.nts.live/shows/guests/episodes/yves-tumor-26th-june-2017
https://benibischof.ch/#tab=4
https://www.editionpatrickfrey.com/en/books/hier-sass-er-beatrice-minger
https://monoskop.org/
https://anthology.rhizome.org/
https://soulellis.com/
http://libraryoftheprintedweb.tumblr.com/
https://doorofperception.com/2013/07/antroposophic-architecture/
https://cwandt.com/
https://www.tate.org.uk/research/publications/tate-papers/07/towards-anarchitecture-gordon-matta-clark-and-le-corbusier
http://www.fourwall.com/blog/2017/1/18/haus-rucker-co-oase-no-7-1972
http://ubu.com/
https://www.ursss.com/
http://www.bb-bureau.fr/post/172010902332/bb-book-b
http://davidrudnick.org/
https://karolinapietrzyk.info
http://www.superlabo.com/catalogue/superlabo.html
https://shop.ditto-london.com/
http://www.innenzines.com/
http://lodretvandret.com/
http://loosejoints.biz/
http://croatanedition.tictail.com/products
http://dienacht-magazine.com/publishing
https://diagonalpress.com/
http://www.superpaper.de/all-issues/
http://www.yardpress.it/
http://shabazzprojects.com/
https://www.newrafael.com/websites/
`

export default linksText.split('\n');
