import React from 'react';
import { css } from '@emotion/core';
import { graphql } from 'gatsby';


import Colors from '../../constants/colors';
import MEDIA_QUERY from '../../constants/media-queries';
import links from '../../data/links';

import Layout from '../../components/Layout';

const buttonCss = css`
  position: fixed;
  
  width: 80%;
  height: 50px;
  right: 1.85rem;
  bottom: 1.65em;
  
  ${MEDIA_QUERY.S} {
    width: 100px;
    right: 2em;
    bottom: 2em;
    height: 100px;
  }
  
  border-width: 0;
  border-radius: 50%;
  background-color: #FF0000;
  color: #FFFFFF;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.GREEN};
  }
`;


const wrapCss = css`
  height: calc(100vh - 150px);
  overflow: hidden;
  position: relative;
`;



const ulCss = css`
  &:first-child {
    animation: scroll calc(1 * var(--scroll-time)) linear infinite;

    @keyframes scroll {
      0%   { top: 0; }
      50%  { top: calc(-1 * var(--scroll-height)); }
      51%  { top: calc(var(--scroll-height)); }
      100% { top: 0; }
    }
  }

  &:nth-child(2) {
    top: var(--scroll-height);
    animation: scroll2 var(--scroll-time) linear infinite;

    @keyframes scroll2 {
      0%    { top: var(--scroll-height); }
      100%  { top: calc(-1 * var(--scroll-height)); }
    }
  }

`;

const liCss = css`
  width: 100%;
  text-align: center;

  &:hover {
    background-color: ${Colors.PURPLE}
  }
`;

const stretchDivCss = css`
  color: black;
  white-space: nowrap;

  font-family: Helvetica, sans-serif;
  font-size: 6.0vw;
  ${MEDIA_QUERY.S} {
    font-size: 3.75vw;
  }
`;


class Links extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      links: props.items,
    };

    this.renderLink = this.renderLink.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.list = null;
    this.height = 0;
  }

  componentDidMount() {
    if (this.list) {
      this.height = this.list.scrollHeight;
      this.forceUpdate();
      document.addEventListener('resize', this.handleResize);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('resize', this.handleResize);
  }
  
  handleResize() {
    this.height = this.list.scrollHeight;
    this.forceUpdate();
  }

  renderLink(link, i) {
    return (
      <li className={liCss.name} key={`link-${i}`}>
        <a 
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={stretchDivCss.name}>
            {link}
          </div>
        </a>
      </li>
    );
  }

  render() {
    const hasHeight = this.height !== 0;
    return (
      <ul
        className={hasHeight ? ulCss.name : ''}
        style={{
          '--scroll-height': `${this.height}px`,
          '--scroll-time': '80s',
          'listStyle': 'none',
          'marginTop': '0',
          'marginBottom': '0',
          'marginLeft': '0',
          'position': 'absolute',
          'top': '0',
        }}
        ref={(node) => { this.list = node; }}
      >
        {this.state.links.map(this.renderLink)}
      </ul>
    );
  }
} 

class RandomButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    const randomLink = this.props.links[Math.floor(Math.random() * links.length)];
    if (window) {
      window.location = randomLink;
    }
  }

  render() {
    return <button 
      className={buttonCss.name}
      onClick={this.handleClick}
    >
      I'm feeling lucky
    </button>;
  }
}

function shuffleArray(array) {
  let counter = array.length;

  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);
    counter--;
    
    let temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }

  return array;
}

const LinksPage = ({ data, location }) => {
  const linksEdges = data.allGoogleSpreadsheetLinksLinks.edges;
  let links = linksEdges.map(edge => edge.node.url);
  links = shuffleArray(links);

  return (
    <Layout location={location}>
      <div className={wrapCss.name}>
        <Links items={links} />
        {/* <Links items={links} /> */}
        {/* <RandomButton links={links} /> */}
      </div>
    </Layout>
  );
};

export default LinksPage;

export const query = graphql`
  query LinkSpreadsheetQuery {
    allGoogleSpreadsheetLinksLinks {
      edges {
        node {
          url,
          id
        }
      }
    }
  }
`;

